var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { css, html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { CerumLoading, LoadingType } from 'common-uicomponents/CerumLoading';
import { DocumentViewer } from 'common-uicomponents/components/DocumentViewer';
import { CerumLegacyButton } from 'common-uicomponents/components/CerumLegacyButton';
import { Checkbox } from '@vaadin/checkbox';
import { OverlayWindow } from 'common-uicomponents/components/OverlayWindow';
import { SigningController } from '../controllers/SigningController';
import { SigningPortalStep } from '../interfaces/SigningPortalStep';
import moment from 'moment';
import { SigningCompletionState } from '../interfaces/SigningCompletionState';
let SigningPageIndex = class SigningPageIndex extends LitElement {
    constructor() {
        super(...arguments);
        this.signingController = new SigningController(this);
        this.tosApproved = false;
    }
    static get is() {
        return 'signup-page-index';
    }
    static get styles() {
        return css `
            .container {
                display: flex;
                justify-content: center;
                max-width: 100dvw;
            }

            .inner-container {
                background-color: white;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                min-width: 20dvw;
                padding: 0.7rem 1rem;
            }

            .container-finish {
                text-align: center;
            }

            canvas,
            img {
                box-shadow: 1px 1px 4px -4px;
                margin: 10px 0px;
                background-color: var(--cerum-white);
                border-radius: 10px;
            }

            img {
                height: 300px;
                width: 400px;
                object-fit: contain;
            }

            .canvas-container {
                display: flex;
                justify-content: center;
                flex-direction: column;
                border-radius: 10px;
            }

            .toolbar-top {
                display: flex;
                padding: 10px 20px;
                background-color: white;
                border-radius: 10px 10px 0px 0px;
                border-bottom: 1px solid lightgray;
                align-self: center;
                width: 90%;
            }

            .toolbar-title {
                align-self: center;
                flex: 1;
                display: flex;
                justify-content: center;
                font-weight: bold;
            }

            cerum-loading {
                --checkmark-height: 100px;
                --checkmark-width: 100px;
            }

            document-viewer {
                padding: 1rem;
                background-color: white;
                border-radius: 10px;
                width: 70%;
                --document-viewer-height: 800px;
            }

            .existing-signature {
                display: flex;
                flex-direction: column;
                padding: 10px;
                border: 1px solid #f2f2f2;
                border-radius: 10px;
                margin: 1rem 0rem 1rem 0rem;
            }

            .information-container {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                margin-bottom: 1rem;
            }

            .information-footer {
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: space-between;
            }

            .signing-footer {
                width: 100%;
                display: flex;
                justify-content: space-between;
            }

            @media screen and (max-width: 1200px) {
                .container {
                    height: 100vh;
                    background-color: white;
                }

                .inner-container {
                    padding: 0;
                    width: 95%;
                    border-radius: 0;
                    height: 100dvh;
                }

                .toolbar-top {
                    padding: 10;
                    align-self: center;
                }

                img {
                    width: 80dvw;
                }

                .information-footer {
                    flex: 1;
                    display: flex;
                    justify-content: end;
                    align-items: end;
                }

                .container-finish {
                    width: 100dvw;
                }

                vaadin-checkbox {
                    font-size: 12px;
                }

                .toolbar-top {
                    padding: 10px 10px;
                }

                cerum-legacy-button {
                    --text-2xs: 12px;
                }
            }
        `;
    }
    /** @override */ render() {
        return html `
            <div class="container">
                <div class="inner-container">${this._getViewForCurrentStep()}</div>
            </div>
        `;
    }
    _getViewForCurrentStep() {
        const currentRender = this.signingController.stepRenderers[this.signingController.currentStep];
        if (!currentRender) {
            return this.renderSigningLoading();
        }
        return html `${currentRender.render()}${currentRender.footer ? currentRender.footer() : ''}`;
    }
    renderSigningLoading() {
        return html `<cerum-loading title="Laster inn..." type="${LoadingType.CIRCLE}"></cerum-loading>`;
    }
    renderSigningConfirmation(signingConfirmationData) {
        const state = this.signingController.getSigningCompletionState(signingConfirmationData);
        return html `<div class="container-finish">
            <cerum-loading
                title="${this._getLoadingTitle(state)}"
                type="${this._getLoadingType(state)}"
            ></cerum-loading>
        </div>`;
    }
    _getLoadingType(state) {
        if (this.signingController.isLoading) {
            return LoadingType.CIRCLE;
        }
        switch (state) {
            case SigningCompletionState.COMPLETED:
                return LoadingType.SUCCESS;
            case SigningCompletionState.FAILED:
                return LoadingType.ERROR;
            default:
                return LoadingType.CIRCLE;
        }
    }
    _getLoadingTitle(state) {
        var _a;
        switch (state) {
            case SigningCompletionState.ALREADYSIGNED:
                return 'Dokumentet er allerede signert';
            case SigningCompletionState.COMPLETED:
                return 'Signering for ' + ((_a = this.signingController.signatureData) === null || _a === void 0 ? void 0 : _a.name) + ' fullført.';
            case SigningCompletionState.FAILED:
                return 'Signering feilet';
            default:
                return 'Ukjent status';
        }
    }
    renderError(title) {
        return html `<div style="padding: 4rem 10rem;">
            <cerum-loading type="loading-error" title="${title !== null && title !== void 0 ? title : 'Ugyldig link'}"></cerum-loading>
        </div>`;
    }
    renderSigningInformationFooter(signingHash, signing) {
        const disabled = Boolean(signingHash && signing);
        return html `<div class="information-footer">
            <cerum-legacy-button
                name="Avis dokument og ikke signer"
                type="danger"
                ?disabled="${disabled}"
            ></cerum-legacy-button>
            <cerum-legacy-button
                .disabled="${disabled}"
                name="Gå videre til signering"
                type="success"
                @click="${() => this._gotoSigning(disabled)}"
            ></cerum-legacy-button>
        </div>`;
    }
    _gotoSigning(disabled) {
        if (!disabled) {
            this.signingController.setCurrentStep(SigningPortalStep.SIGNING);
        }
    }
    renderSigningFooter() {
        const { signingCanvasController } = this.signingController;
        return html ` <div class="signing-footer">
            <cerum-legacy-button
                name="Tøm"
                type="danger"
                @click="${() => signingCanvasController === null || signingCanvasController === void 0 ? void 0 : signingCanvasController.clearCanvas()}"
            ></cerum-legacy-button>

            <div style="display: flex;">
                <vaadin-checkbox
                    label="Godkjenn dokument og signatur"
                    @checked-changed="${(event) => (this.tosApproved = !event.detail.value)}"
                ></vaadin-checkbox>
                <cerum-legacy-button
                    .disabled="${this.tosApproved || (signingCanvasController === null || signingCanvasController === void 0 ? void 0 : signingCanvasController.isSignatureInvalid())}"
                    name="Bekreft signatur"
                    type="success"
                    @click="${() => this.signingController.confirmSignature()}"
                ></cerum-legacy-button>
            </div>
        </div>`;
    }
    renderSigningConfirmationFooter() {
        return html ``;
    }
    renderSigningInformation() {
        var _a, _b;
        const data = (_a = this.signingController.stepRenderers[SigningPortalStep.INFORMATION]) === null || _a === void 0 ? void 0 : _a.data;
        if (!data) {
            return html ``;
        }
        const { name, role_name: roleName, signing } = data;
        return html `<div style="display: flex; flex-direction: column; width: 100%;">
            <h2 style="text-align: center">Cerum Signering</h2>
            <div class="information-container">
                <div>
                    <span style="font-weight: bold">Navn:</span>
                    <span>${name}</span>
                </div>
                <div>
                    <span style="font-weight: bold">Rolle:</span>
                    <span>${roleName}</span>
                </div>
                <cerum-legacy-button
                    style="--text-2xs: .75rem; white-space: nowrap;"
                    name="Se dokument"
                    type="info"
                    @click="${() => this._openDocument()}"
                ></cerum-legacy-button>
            </div>

            ${signing
            ? html ` <div>
                      <div class="existing-signature">
                          <span style="font-weight: bold; color: red">
                              Allerede signert, her ser du eksisterende signatur
                          </span>
                          <img src="${(_b = signing.picture_evidence_url) !== null && _b !== void 0 ? _b : ''}" />
                          <div>
                              <span style="font-weight: bold">Signert av:</span>
                              <span>${signing.name}</span>
                          </div>
                          <div>
                              <span style="font-weight: bold">Dato: </span>
                              <span>${moment(signing.signing_date).format('DD.MM.YY HH:mm')}</span>
                          </div>
                      </div>
                  </div>`
            : html `<div style="height: 1rem"></div>`}
        </div>`;
    }
    _openDocument() {
        const { signatureData } = this.signingController;
        if (!(signatureData === null || signatureData === void 0 ? void 0 : signatureData.document.document_url)) {
            return;
        }
        OverlayWindow.asyncInject({
            title: signatureData.name,
            componentTagName: DocumentViewer,
            attributes: { documentUrl: signatureData.document.document_url },
            fullscreen: true,
        });
    }
    renderSigning() {
        return html `<div class="canvas-container">
            <div class="toolbar-top">
                <span class="toolbar-title">Cerum Signering</span>
            </div>
            <canvas
                id="signing-area"
                @touchstart="${() => this._onCanvasClick()}"
                @mousedown="${() => this._onCanvasClick()}"
            ></canvas>
        </div>`;
    }
    _onCanvasClick() {
        if (this.signingController.signingCanvasController &&
            this.signingController.signingCanvasController.showHelperText) {
            this.signingController.signingCanvasController.removeHelperText();
            this.signingController.signingCanvasController.showHelperText = false;
        }
    }
    getCanvas() {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            const canvas = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#signing-area');
            if (canvas) {
                return canvas;
            }
        });
    }
};
SigningPageIndex._deps = [CerumLoading, DocumentViewer, CerumLegacyButton, Checkbox, OverlayWindow];
__decorate([
    state(),
    __metadata("design:type", SigningController)
], SigningPageIndex.prototype, "signingController", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], SigningPageIndex.prototype, "tosApproved", void 0);
SigningPageIndex = __decorate([
    customElement(SigningPageIndex.is)
], SigningPageIndex);
export { SigningPageIndex };
